<template>
  <section class="multistep">
    <div class="container">
      <div class="steps-count">
        <div class="steps">

          <a @click="moveToStep('firstStep', true )" class="done">1</a>
          <a @click="moveToStep('secondStep', true )" class="done">2</a>

          <a class="active uk-disabled">3</a>

          <a v-if="stepHasValues(4)" @click="moveToStep('fourthStep')">4</a>
          <a v-if="!stepHasValues(4)" class="uk-disabled">4</a>


        </div>
      </div>
      <div class="content">
        <h1>Fotky nemovitosti</h1>
        <p class="error">Vyberte ze svého disku nebo vyfoťte ve formátu JPG alespoň 3 fotografie. Přetažením můžeme určovat jejich pořadí.
          První fotografie se použije jako náhledová.</p>
        <form class="form-label form-js-label">
          <div class="row marg">
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <div class="file">
                  <input type="file" accept="image/jpeg" multiple="multiple" @change="previewMultiImage"
                         class="form-control-file" id="my-file">
                  <label class="button small" for="my-file">Vyfoťte/Vyberte obrázky</label>
                </div>
              </div>
              <!-- <div class="uk-width-1-2">
                 <router-link to="#" class="button white small is-rounded" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}"  @click="toggleCamera" style="width: 100%;">Vyfotit</router-link>
               </div>-->
            </div>
          </div>
          <!--
          <div class="row marg">
            <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
              <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
              <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
              <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
            </div>
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                  <p class="center foto-button">
                    <button type="button" class="button white small" @click="takePhoto">
                      <img alt="advert-picture" src="../../assets/images/camera-plus.svg" data-uk-svg>
                  </button>
                  </p>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
                  <p class="center foto-button">
                  <a id="downloadPhoto" download="my-photo.jpg" class="button small" role="button" @click="downloadImage" @change="previewMultiImage">
                    Download
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          -->
          <!--
          <div class="row marg">
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-expand">
                <p>Natočte a přímo nahrajte vlastní video</p>
              </div>
              <div class="uk-width-auto">
                <router-link to="#" class="button white small">Natočit Video</router-link>
              </div>
            </div>
          </div>
          -->
          <div class="row" style="min-height: 9rem;">
            <draggable
              v-model="preview_list"
              item-key="id"
              class="uk-grid-small"
              id='photo-container'
              data-uk-grid>
              <template #item="{element}">
                <PrevieImage :image="element" @imageclick="identifyPhoto"></PrevieImage>
              </template>
            </draggable>
          </div>
          <div class="row">
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <a class="button small" v-if="isMobile" @click="reset">Smazat</a>
                <a class="button small" v-if="delete_list.length!==0" @click="reset">Smazat</a>
              </div>
            </div>
          </div>
          <p v-if="validated && showImgMessage">Vyberte alespoň 3 obrázky</p>
          <div class="row end">
            <a @click="moveToStep('secondStep', true)" class="button white small">Zpět</a>
            <a @click="moveToStep('fourthStep')" class="button small">Pokračovat dále</a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Vuedraggable from "vuedraggable"
import {defineComponent} from "vue";
import PrevieImage from "@/components/PrevieImage";
import Delayer from "../../../libs/delayer";
import {mapGetters} from "vuex";

export default defineComponent({
  components: {
    draggable: Vuedraggable,
    PrevieImage
  },
  name: "ThirdStep",
  data() {
    return {
      id: null,
      step: {},
      layout: '',
      add: null,
      preiousStep: {
        typ: 0
      },
      file: null,
      previewImage: null,
      image: null,
      imageId:1,
      imageElementId:1,
      preview_list: [],
      delete_list:[],
      test_list: [],
      image_list: [],
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      index: '',
      validated: false,
      showImgMessage: false,
      touch:false
    }
  },
  async created() {
    this.id = this.$route.params.id;
    let add = this.$store.getters.advertById(this.id);
    if( typeof add == 'undefined' ) {
      this.$router.push({ path: '/' });
      return;
    }
    if( add.activated ){
      this.$router.push({name: 'viewonly', params: {id: this.id}})
    }

    this.add = add;
    this.step = add.steps["3"];
    let images = await this.$store.getters.getImages(add.id);
    for (const position in images ){
      let img = images[position];
      if( img.base || img.path ){
        this.preview_list.push(img);
      }
    }
  },
  computed:{
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
      } else {
      return false
      }
    }
  },
  methods: {
    /*async uploadImage(event){
        const image = event.target.files[0];
        this.formPart.image = image;
        this.previewImage = URL.createObjectURL(image);
      },
      async uploadVideo(event){
        const video = event.target.files[0];
        this.formPart.video = video;
      },*/

    /*toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },*/

    /*createCameraElement() {
      this.isLoading = true;
      
      const constraints = (window.constraints = {
				audio: false,
				video: true
			});


			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
					alert("May the browser didn't support or there is some errors.");
				});
    },*/

    /*stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
    },
    
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },*/

    /*downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
    .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },*/

    previewMultiImage: function (event) {
      var input = event.target;

      let sources = [];
      if (!input.files.length) return;
      
      // handling file saving
      Array.prototype.forEach.call(input.files, (file) => {
        file.id = String(this.imageId);
        this.imageId++;
        this.image_list.push(file);
      });
      // file display
      const files = [...input.files].map((file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });
  /// resolving reading promise and assigning ID to the DOM element
      const res = Promise.all(files)
      .then((res) => sources.push(...res))
      .then(() => {
        // console.log(sources)
        sources.forEach((source) => {
        let img = {base: source, modified: true, path: null, dataid: this.imageElementId};        
        this.preview_list.push(img)
        this.imageElementId++;
      });
    });
  
    },
    reset: function () {
      if(!this.isMobile){
      this.delete_list.forEach((fileDel) => {
        this.image_list = this.image_list.filter((file) => {
          return file.id !== fileDel.dataset.id;
        });
        this.preview_list = this.preview_list.filter((file) => {
          return String(file.dataid) !== fileDel.dataset.id;
        });
        const parentEl = fileDel.closest('.preview');
        document.querySelector('#photo-container').removeChild(parentEl);
      });
      this.delete_list = [];
      return;
      } else{
        this.image_list = [];
        this.preview_list = [];
      }
    },
    async save() {
      if( !this.validate() ){
        Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        return;
      }

      let advert = {
        id: this.id,
        step: this.preview_list.length ? {images: true} : {},
        images: this.preview_list
      };
      await this.$store.dispatch('updateStepThree', advert);
      return true;
    },
    validate(){
      let valid = true;
      if( this.preview_list.length < 3 ){
        this.showImgMessage = true;
        valid = false;
      }
      this.validated =true;
      return valid;
    },
    async moveToStep(stepName, notValidate = false) {
      if (await this.save() || notValidate) {
        this.$router.push({name: stepName, params: {id: this.id}});
      }
    },
    stepHasValues(step) {
      if( !this.add ){
        return false;
      }
      return Object.keys(this.add.steps[step]).length > 0;
    },
    identifyPhoto(e){
      // console.log(e.target);
      if(![...e.target.classList].includes('img-fluid'))return;                       
      //// targeting for delete by button
      
      if (e.target.style.border !== "2px solid red") {
        this.delete_list.push(e.target);
        e.target.style.border = "2px solid red";
        return;
      } else if (e.target.style.border === "2px solid red") {
        this.delete_list = this.delete_list.filter((file) => {
        return file.dataset.id !== e.target.dataset.id;
        });
        e.target.style.border = "none";
        return;
      }
      // console.log(this.delete_list)
      
    },
    
  },
});
</script>

<style lang="less" scoped>
section.multistep {
  .container {
    position: relative;
    max-width: 42.8rem;
    height: 92vh;
    overflow: auto;
    margin: auto;
    background: #fff;
    box-sizing: border-box;
    padding-bottom: 4rem;

    .steps-count {
      background: #fff;
      padding: 1.8rem 0;

      .steps {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        max-width: 27rem;
        margin: auto;

        a {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 4.3rem;
          height: 4.3rem;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 2.1rem;
          line-height: 2.1rem;
          color: #03A3BD;
          background: #F2F2F2;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);

          &.active {
            background: #03A3BD;
            color: #fff;

            &:hover {
              background: #fff;
              color: #03A3BD;
            }
          }

          &:hover {
            text-decoration: none;
            background: #03A3BD;
            color: #fff;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: -2rem;
            bottom: 0;
            margin: auto;
            width: 0.9rem;
            height: 1.7rem;
            background: url('../../assets/images/ico-arrow-right.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
          }

          &:last-of-type {
            &:before {
              content: none;
            }
          }
        }
      }
    }

    .content {
      padding: 2rem 2rem 6rem;

      h1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.5rem;
        text-align: center;
        color: #7C7C7B;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        max-width: 33rem;
        margin: auto;
        margin-top: 0;
        margin-bottom: 4rem;
      }

      form {
        .row {
          h3 {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #03A3BD;
            margin-top: 2rem;
            margin-bottom: 1rem;
          }

          .file {
            position: relative;

            input {
              position: absolute;

              opacity: 0;
            }
          }

          .uk-grid {
            margin-bottom: 2rem;
            }

          &.end {
            padding: 2rem 0;
            display: flex;
            justify-content: space-between;
          }

          &.marg {
            margin-bottom: 2rem;

            .camera-box {
              margin-top: 2rem;
            }

            .camera-shoot,
            .camera-download {
              > p {
                margin-top: 2rem;
                margin-bottom: 2rem;

                button {
                  min-width: 100%;

                  svg, img {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.txtr {
  text-align: right;
}
.outline{
  border:1px solid green;
}
</style>