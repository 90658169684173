<template>
  <div class="preview uk-width-1-3" @click='emitEvent($event)' @touchstart="touchStart($event)">
    <picture>
      <img :src="image.base" class="img-fluid" :data-id="image.dataid"/>
    </picture>
  </div>
</template>

<script>
import { once } from 'events';

export default {
  name: "PrevieImage",
  props: ["image"],
  emits:['imageclick'],
  data(){
    return {
      touch:false,
    }
  },
  methods:{
    emitEvent(e){
      // console.log(e)
      // e.preventDefault();
      e.stopPropagation();
      // console.log(this.touch)  
      this.$emit('imageclick',e);
      return;  
    },
    touchStart(e){
      e.preventDefault();
      e.stopPropagation();
      this.touch=true;
      // console.log(this.touch)
      document.querySelector('.img-fluid').addEventListener('touchmove',(event)=>{
        // this.touch=false;
        // console.log(event)
        // console.log(this.touch)
        
        return;
      },{once:true})

      document.querySelector('.img-fluid').addEventListener('touchend',(event)=>{
        // console.log(event)
        // console.log(this.touch)
        // if(this.touch === true){
        //   this.emitEvent(e);
        //   return;
        // } else{
        //   return;
        // }
      },false)
    },
    

  }
}
</script>

<style lang="less" scoped>
.preview {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 11.4rem;
  width: 100%;
  height: 7.8rem;

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    z-index: 2;

    img {
      max-height: 1.1rem;
    }
  }
}

</style>